<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-table
            grid
            title=""
            :data="grid.data"
            :columns="grid.columns"
            hide-header
            hide-bottom
            :rows-per-page-options="[0]"
            virtual-scroll
          >
            <template v-slot:item="props">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                  <q-card-section horizontal class="bg-grey-2">
                    <q-card-section class="q-pt-xs">
                      <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.equipmentName }}</div>
                    </q-card-section>
                  </q-card-section>
                  <q-card-section class="grid-card-etc">
                    <div class="text-grid-etc-title">
                      설비관리번호 :
                    </div>
                    <div class="text-grid-etc">
                      {{ props.row.equipmentNo }}
                    </div>
                  </q-card-section>
                </q-card>
              </div>
            </template>
          </q-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mobileConfig from '@/js/mobileConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        dailyCheckId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      year: '',
      grid: {
        merge: [
        ],
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            type: 'link',
            align: 'center',
            style: 'width:220px',
            sortable: false,
          },
          // {
          //   name: 'completeFlagName',
          //   field: 'completeFlagName',
          //   label: '완료여부',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: false,
          // },
        ],
        data: [],
      },
      data: {
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        mobileImproveModelList: [], 
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.checkStatusCd && this.data.checkStatusCd !== 'MCSC000015', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000004', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          dailyCheckId: this.data.dailyCheckId,
          isApprContent: true
        },
        approvalRequestName: '일상점검 ['+this.data.dailyName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.dailyCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
    isRemove() {
      return this.popupParam.dailyCheckId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.getUrl = mobileConfig.sop.min.equipment.plan.daily.get.equipment.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.plan.daily.update.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.plan.daily.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.daily.complete.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.daily.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.dailyCheckId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.dailyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.lineCd = this.data.lineCd;

          this.grid.data = _result.data.equipList
        },);
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(ect,row) {
      this.popupOptions.param = {
        dailyCheckId: this.data.dailyCheckId,
        equipmentCd: row ? row.equipmentCd : '',
        data: this.data,
        lineCd: this.lineCd,
        equipmentName: row.equipmentName,
      };
      this.popupOptions.title = row.equipmentName + ' (' + row.equipmentNo + ')' + " " + this.data.checkDate; 
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/mim/inspection/equipmentDailyChecking.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    /* eslint-disable no-unused-vars */
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail')
    },
  }
};
</script>
